import { BlockContainer } from '@/components/Blocks';
import { createBlock } from '@/lib/parsers/blocks';
import BlockHeader from '../BlockHeader';
import CtaButton from '../CtaButton';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';

const RichText = createBlock<'blocks_richText_BlockType'>(
  ({ heading, content, linkFieldMultiple = [], _blockMeta: meta, anchor }) => {
    return (
      <BlockContainer
        {...{
          meta,
          marginY: true,
          disableTransition: true,
        }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <BlockHeader
              maxWidth="none"
              customAnchor={anchor}
              heading={heading}
              HeadingProps={{
                variant: 'h4',
                html: true,
              }}
              content={content}
              ContentProps={{ cx: { width: 'full' } }}
            />
            {!!linkFieldMultiple?.length && (
              <Flex alignItems="start" rowGap="margin" cx={{ mT: 'sm' }}>
                {linkFieldMultiple?.map((item, i) => {
                  return <CtaButton key={i} size="small" {...item} />;
                })}
              </Flex>
            )}
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default RichText;
