import BlockHeader from '@/components/BlockHeader';
import SimpleGrid from '@/components/SimpleGrid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import ExternalPresenterCardBlock from '../ExternalPresenterCardBlock';
import PresenterCardBlock from '../PresenterCardBlock';

export const PresenterCardsBlock = createBlock<'blocks_presenterCards_BlockType'>(
  ({ _blockMeta: meta, anchor, heading, children = [] }) => {
    return (
      <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
        <BlockHeader
          maxWidth="none"
          customAnchor={anchor}
          heading={heading}
          HeadingProps={{ html: true }}
          cx={{ mB: '2xl' }}
        />
        <SimpleGrid md={6} lg={4} rowGutter={false} cx={{ rowGap: { xs: 'xl', lg: '3xl' } }}>
          {children.map((item) =>
            item.__typename === 'blocks_presenterCard_BlockType' ? (
              <PresenterCardBlock key={item.id} {...item} />
            ) : (
              <ExternalPresenterCardBlock key={item.id} {...item} />
            )
          )}
        </SimpleGrid>
      </BlockContainer>
    );
  }
);

export default PresenterCardsBlock;
