import { createEventDate } from '@/lib/utils/createEventDate';
import { fmtCategoryNames } from '@/lib/utils/format';
import Card from '../Card';
import Icon from '../Icon';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import { EntryCardProps } from './EntryCardProps';
export type ArticleCardProps = EntryCardProps<'article'>;

export const ArticleCard = ({ item, ...props }: ArticleCardProps) => {
  const {
    uri: href,
    title,
    pageTitle,
    summary: description,
    postDate,
    contentTypeCategorySingle,
    eventStartDate,
    eventEndDate,
  } = item;

  const contentTypeName = fmtCategoryNames(contentTypeCategorySingle);

  const { eventDate, isPast } = createEventDate(eventStartDate, eventEndDate);

  return (
    <Card
      colorSet="primaryLight"
      paper
      fullHeight
      elevation={false}
      {...props}
      item={{
        href,
        title: pageTitle ?? title,
        meta: [contentTypeName, postDate].filter(Boolean),
        description,
      }}>
      <Card.Body cx={{ mX: 'xs', mT: 'md' }}>
        <Card.Meta />
        <Card.Title
          variant="h5"
          cx={{ mB: eventDate ? 'none' : 'lg', mT: 'lg' }}
          autoMargin={!!eventDate}
        />
        {eventDate && (
          <Flex direction="row" alignItems="center" spacing="3xs" cx={{ mB: 'lg' }}>
            <Icon name={isPast ? 'video' : 'calendar'} />
            <Txt variant="small">{eventDate}</Txt>
          </Flex>
        )}
      </Card.Body>
      <Card.Foot cx={{ mX: 'xs', mB: 'xl' }}>
        <Card.Cta
          variant="text"
          size="small"
          EndIconProps={{ name: 'arrowRight', cx: { color: 'accent' } }}
          cx={{ fontSize: 'medium' }}
        />
      </Card.Foot>
    </Card>
  );
};
