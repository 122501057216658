import Box from '@/components/ui/Box';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { fmtPodcastLink } from '@/lib/utils/format';
import { toString } from '@liquorice/allsorts-craftcms-nextjs';
import { BlockContainer } from '../..';

const PodcastBlock = createBlock<'blocks_podcast_BlockType'>(
  ({ _blockMeta: meta, anchor: customAnchor, websiteUrl: podcastUrl, label }) => {
    const podcast = fmtPodcastLink(podcastUrl);
    const podcastLabel = toString(label);

    if (!podcast) return null;

    return (
      <BlockContainer
        {...{
          meta,
          customAnchor,
          marginY: true,
          disableTransition: true,
          BlockLinkProps: { GridColProps: { offsetMd: 2, md: 8 } },
        }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <Box as="iframe" aria-label={podcastLabel} src={podcast} width="100%" height="152" />
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default PodcastBlock;
