import BlockHeader from '@/components/BlockHeader';
import { BlockContainer } from '@/components/Blocks';
import CtaButton from '@/components/CtaButton';
import Image from '@/components/Image';
import Video from '@/components/Video';
import Box from '@/components/ui/Box';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { useBreakpointMax } from '@/theme';

const ImageAndTextBlock = createBlock<'blocks_imageAndText_BlockType'>(
  ({
    heading,
    content,
    imageSingle,
    videoUrl,
    flipAlignment,
    linkFieldMultiple,
    _blockMeta: meta,
    anchor,
  }) => {
    const md = useBreakpointMax('md');

    const TextBlock = () => {
      return (
        <Box cx={{ pR: { md: !flipAlignment ? '4xl' : 'none' } }}>
          <BlockHeader
            customAnchor={anchor}
            maxWidth="none"
            heading={heading}
            HeadingProps={{ variant: 'h4', html: true }}
            content={content}
          />
          {!!linkFieldMultiple?.length && (
            <Flex alignItems="start" rowGap="margin" cx={{ mT: 'sm' }}>
              {linkFieldMultiple?.map((item, i) => {
                return <CtaButton key={i} size="small" {...item} />;
              })}
            </Flex>
          )}
        </Box>
      );
    };

    const MediaBlock = () => {
      if (videoUrl) return <Video videoUrl={videoUrl} />;
      return (
        <Image
          alt={imageSingle?.alt ?? ''}
          fixedRatio={md}
          ratio={md ? 'landscape' : undefined}
          {...imageSingle}
        />
      );
    };

    return (
      <BlockContainer {...{ meta, disableTransition: true, marginY: true }}>
        <Grid
          rowGutter
          cx={{
            alignItems: 'center',
            flexDirection: {
              xs: 'column-reverse',
              md: 'row',
            },
          }}>
          <Grid.Col offsetMd={1} md={5}>
            {flipAlignment && !md ? <MediaBlock /> : <TextBlock />}
          </Grid.Col>
          <Grid.Col offsetMd={flipAlignment ? 1 : 'none'} md={flipAlignment ? 4 : 5}>
            {flipAlignment && !md ? <TextBlock /> : <MediaBlock />}
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default ImageAndTextBlock;
