import BlockHeader from '@/components/BlockHeader';
import CtaButton from '@/components/CtaButton';
import Divider from '@/components/ui/Divider';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { useBreakpointMax } from '@/theme';
import { BlockContainer } from '../..';

const KeyTakeawaysBlock = createBlock<'blocks_keyTakeaways_BlockType'>(
  ({ linkFieldMultiple = [], linkField, content, heading, _blockMeta: meta, anchor }) => {
    const sm = useBreakpointMax('sm');

    return (
      <>
        <BlockContainer
          {...{
            meta,
            marginY: true,
            disableContainer: sm,
            disableTransition: true,
          }}>
          <Grid>
            <Grid.Col offsetMd={2} md={8}>
              <BlockHeader
                colorSet="secondary"
                paper
                cx={{ p: 'lg' }}
                customAnchor={anchor}
                heading={heading}
                HeadingProps={{ variant: 'h5', html: true }}
                content={content}
                ContentProps={{ autoMargin: true }}
                maxWidth="none">
                {linkField && <CtaButton size="small" {...linkField} />}
              </BlockHeader>
            </Grid.Col>
          </Grid>
        </BlockContainer>
        {!!linkFieldMultiple?.length && (
          <BlockContainer marginY disableTransition>
            <Grid>
              <Grid.Col offsetMd={2} md={8}>
                <Divider cx={{ mB: 'sm' }} />
                {linkFieldMultiple?.map((item, idx) => (
                  <Flex direction="row" key={idx}>
                    <Txt color="accent" cx={{ mR: '3xs' }}>
                      {'[' + (idx + 1) + ']'}
                    </Txt>
                    <CtaButton {...item} endIcon={undefined} wrap />
                  </Flex>
                ))}
              </Grid.Col>
            </Grid>
          </BlockContainer>
        )}
      </>
    );
  }
);

export default KeyTakeawaysBlock;
