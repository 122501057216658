import BlockHeader from '@/components/BlockHeader';
import CardGrid from '@/components/CardGrid';
import CtaButton from '@/components/CtaButton';
import { ArticleCard } from '@/components/EntryCard';
import SkeletonCards from '@/components/SkeletonCards';
import Flex from '@/components/ui/Flex';
import { useLatestEntries } from '@/lib/getters/useLatestEntries';
import { createBlock } from '@/lib/parsers/blocks';
import { useTranslations } from 'next-intl';
import { BlockContainer } from '../../index';

const LatestArticlesBlock = createBlock<'blocks_latestArticles_BlockType'>(
  ({ heading, _blockMeta: meta, anchor }) => {
    const { result, loading } =
      useLatestEntries(
        { section: 'article', limit: 4, orderBy: 'postDate desc' },
        'article_Entry'
      ) ?? {};

    const t = useTranslations('actions');

    return (
      <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
        <Flex
          justifyContent="between"
          cx={{
            mB: '2xl',
            direction: { xs: 'column', lg: 'row' },
            alignItems: { xs: 'start', lg: 'end' },
            rowGap: { xs: 'sm', lg: 'none' },
          }}>
          <BlockHeader
            maxWidth="none"
            customAnchor={anchor}
            heading={heading}
            HeadingProps={{ html: true }}
          />
          <CtaButton href="/news">{t('viewAll')}</CtaButton>
        </Flex>
        {loading ? (
          <SkeletonCards
            amount={4}
            md={6}
            lg={3}
            SkeletonItems={{ items: [{ variant: 'rounded', height: 300 }] }}
          />
        ) : (
          result && (
            <CardGrid
              items={result}
              Component={ArticleCard}
              ItemProps={{
                md: 6,
                lg: 3,
              }}
            />
          )
        )}
      </BlockContainer>
    );
  }
);

export default LatestArticlesBlock;
