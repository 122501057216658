import BlockHeader from '@/components/BlockHeader';
import UnstyledAccordion from '@/components/UnstyledAccordion';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { vars } from '@/theme/vars.css';
import { BlockContainer } from '../..';

export const AccordionBlock = createBlock<'blocks_accordion_BlockType'>(
  ({ _blockMeta: meta, heading, content, children, anchor }) => {
    return (
      <BlockContainer
        {...{
          meta,
          marginY: true,
          disableTransition: true,
        }}>
        <Grid>
          <Grid.Col offsetMd={2} md={8}>
            <BlockHeader
              customAnchor={anchor}
              heading={heading}
              content={content}
              maxWidth="none"
              HeadingProps={{ variant: 'h4', html: true }}
              cx={{ mB: 'xl' }}
            />
            <UnstyledAccordion
              items={
                children?.map((child, index) => {
                  return {
                    index,
                    style: { borderColor: vars.palette.colors.neutralLight },
                    HandleProps: { cx: { fontSize: 'large' } },
                    LabelProps: {
                      children: child.heading ? (
                        <Txt html variant="large">
                          {child.heading}
                        </Txt>
                      ) : (
                        ''
                      ),
                    },
                    CaretProps: {
                      openIconProps: { cx: { color: 'accent' } },
                    },
                    BodyProps: {
                      index,
                      children: child.content ? <Txt html>{child.content}</Txt> : '',
                    },
                  };
                }) ?? []
              }
            />
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default AccordionBlock;
