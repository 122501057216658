'use client';

import { createEventDate } from '@/lib/utils/createEventDate';
import { fmtCategoryNames } from '@/lib/utils/format';
import Card from '../Card';
import Icon from '../Icon';
import Divider from '../ui/Divider';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import { EntryCardProps } from './EntryCardProps';

export type ListCardProps = EntryCardProps<'article'>;

export const ListCard = ({ item, ...props }: ListCardProps) => {
  const {
    uri: href,
    title,
    pageTitle,
    summary: description,
    postDate,
    contentTypeCategorySingle,
    eventStartDate,
    eventEndDate,
  } = item;

  const contentTypeName = fmtCategoryNames(contentTypeCategorySingle);

  const { eventDate, isPast } = createEventDate(eventStartDate, eventEndDate);

  return (
    <Card
      elevation={false}
      colorSet="white"
      paper
      {...props}
      item={{
        href,
        title: pageTitle ?? title,
        meta: [contentTypeName, postDate].filter(Boolean),
        description,
      }}>
      <Card.Body cx={{ rowGap: 'xs', mY: 'xs' }}>
        <Card.Meta variant="small" />
        <Card.Title variant="h5" as="span" />
        {eventDate && (
          <Flex direction="row" alignItems="center" spacing="3xs">
            <Icon name={isPast ? 'video' : 'calendar'} />
            <Txt variant="small">{eventDate}</Txt>
          </Flex>
        )}
        <Card.Cta
          variant="text"
          size="small"
          EndIconProps={{ name: 'arrowRight', cx: { color: 'accent' } }}
          cx={{ fontSize: 'medium' }}
        />
      </Card.Body>
      <Divider direction="horizontal" cx={{ color: 'neutralLight' }} />
    </Card>
  );
};
