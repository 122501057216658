import BlockHeader from '@/components/BlockHeader';
import CtaButton from '@/components/CtaButton';
import { ProfileCard } from '@/components/EntryCard';
import SimpleGrid from '@/components/SimpleGrid';
import Flex from '@/components/ui/Flex';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { useProfileCardsBlock } from './useProfileCardsBlock';

const ProfileCardsBlock = createBlock<'blocks_profileCards_BlockType'>(
  ({ _blockMeta: meta, heading, linkField, anchor, ...props }) => {
    const items = useProfileCardsBlock(props) ?? [];

    return (
      <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
        <Flex
          justifyContent="between"
          cx={{
            mB: '2xl',
            direction: { xs: 'column', lg: 'row' },
            alignItems: { xs: 'start', lg: 'end' },
            rowGap: { xs: 'sm', lg: 'none' },
          }}>
          <BlockHeader
            maxWidth="none"
            customAnchor={anchor}
            heading={heading}
            HeadingProps={{ html: true }}
          />
          {linkField && <CtaButton {...linkField} />}
        </Flex>
        <SimpleGrid md={6} lg={4} rowGutter={false} cx={{ rowGap: { xs: 'xl', lg: '3xl' } }}>
          {items.map((item, index) => (
            <ProfileCard
              key={index}
              item={item}
              disableLinkWrap={true}
              PhoneProps={{ enableLinkWrap: true }}
              EmailProps={{ enableLinkWrap: true }}
              TitleProps={{ enableLinkWrap: true }}
            />
          ))}
        </SimpleGrid>
      </BlockContainer>
    );
  }
);

export default ProfileCardsBlock;
