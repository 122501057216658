import Card from '@/components/Card';
import { CardBase } from '@/components/Card/useCard';
import { createBlock } from '@/lib/parsers/blocks';

export const ExternalPresenterCardBlock = createBlock<'blocks_externalPresenterCard_BlockType'>(
  ({ imageSingle, heading, label: role, label2: organisation, websiteUrl: linkedinUrl }) => {
    const item: CardBase = {
      title: heading,
      titleComponent: 'span',
      image: imageSingle,
      meta: [role, organisation].filter(Boolean),
      metaDivider: false,
      linkedinUrl,
    };

    return (
      <Card disableLinkWrap elevation={false} fullHeight colorSet="white" paper item={item}>
        <Card.Image ratio="landscape" transform="fullFit" />
        <Card.Body cx={{ pT: 'md' }}>
          <Card.Title variant="h5" html />
          <Card.Meta wrapProps={{ direction: 'column', wrap: true }} />
          <Card.Linkedin cx={{ mT: '3xs' }} />
        </Card.Body>
      </Card>
    );
  }
);

export default ExternalPresenterCardBlock;
